// Navigation Controller

( function( $, window, document, undefined ) {
	
	let NavigationController = class NavigationController {

		constructor() {
			let _ = this;

			_.$button = $('.js-menu-toggle');

			_.openClass = 'menu-is-open';
			_.openingClass = 'menu-is-opening';
			_.closingClass = 'menu-is-closing';

			_.duration = 400;

			_._bindEvents();
		}

		_bindEvents() {
			let _ = this;

			_.$button.on( 'click', (event) => {
				_.toggle();
			} );

			$(document).on( 'keydown', (event) => {
				let escKeyCode = 27;

				if ( escKeyCode === event.keyCode && _.isOpen() ) {
					_.close();
				}
			} );

			return _;
		}

		isOpen() {
			let _ = this;

			return $('body').hasClass( _.openClass );
		}

		open() {
			let _ = this;

			// Stop the body from being able to scroll
			window.ScrollController.freeze();

			$('body').removeClass( _.closingClass );
			$('body').addClass( _.openingClass );

			$('body').addClass( _.openClass );

			setTimeout( () => {
				$('body').removeClass( _.openingClass );
			}, _.duration );

			return _;
		}

		close() {
			let _ = this;

			// Restore the body scroll functionality
			window.ScrollController.restore();

			$('body').removeClass( _.openingClass );
			$('body').addClass( _.closingClass );

			$('body').removeClass( _.openClass );

			setTimeout(function() {
				$('body').removeClass( _.closingClass );
			}, _.duration );

			return _;
		}

		toggle() {
			let _ = this;

			if ( _.isOpen() ) {
				_.close();
			} else {
				_.open();
			}

			return _;
		}

	};

	let _navigationController = new NavigationController();

	window._navigationController = _navigationController;

} )( jQuery, window, document );
