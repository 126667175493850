// Run when Juicer feed has loaded

( function( $, window, document, undefined ) {

	let JuicerFeedController = class JuicerFeedController {

		constructor() {
			let _ = this;
		}

		run() {
			let _ = this;

			// Manifest of tasks to run
			_._bindTouchEvents();
			_._matchButtonsStyle();
			_._fixBrokenImages();
			// If object-fit isn't supported, use backgrounds instead.
			if ( !Modernizr.objectfit ) {
				_._mapImagesToBackgrounds();
			}
		}

		/**
		 * Add touch events to show hover state.
		 */
		_bindTouchEvents() {
			let _ = this,
					$feed = $('.juicer-feed'),
					hoverClass = 'has-hover';

			$feed.on( 'touchstart', (event) => {
				let $target = $(event.target),
						$activeItems = $('.juicer-feed li.feed-item.' + hoverClass );

				if ( !$target.is('li.feed-item') ) {
					$target = $target.closest('li.feed-item');
				}

				// If still not correct item, exit early
				if ( !$target.is('li.feed-item') ) {
					$activeItems.removeClass( hoverClass );
					return;
				}

				$activeItems.removeClass( hoverClass );
				$target.addClass( hoverClass );
			} );
		}

		/**
		 * Match the Juicer button style to the style of the website.
		 */
		_matchButtonsStyle() {
			let $juicerButton = $('.juicer-button');

			$juicerButton.html( `<span>${$juicerButton.html()}</span>` );
		}

		/**
		 * Listen for image load errors and add a fallback class.
		 */
		_fixBrokenImages() {
			let _ = this,
					$feed = $('.juicer-feed'),
					defaultImageSrc = wp.template_directory_uri + '/dist/images/social-profile.jpg';

			// Turn off existing events to prevent overlap
			$feed.find('.j-poster img').off( 'error.Juicer' );

			$feed.find('.j-poster img').on( 'error', (event) => {
				let $target = $(event.target);

				if ( !$target.is('img') ) {
					$target = $target.closest('img');
				}

				$target.attr( 'src', defaultImageSrc );
			} );
		}

		/**
		 * If object-fit isn't supported, hide img tags and 
		 * use the source as a background image instead.
		 * This lets us mimic object-fit with background-size.
		 */
		_mapImagesToBackgrounds() {
			let $imagePosts = $('.j-instagram, .j-twitter.image-post, .j-facebook.image-post, .j-video');

			$imagePosts.each( (index, post) => {
				let $post = $(post),
						$image = $post.find( '.j-image' );

				$image.css( {
					"background-image": `url("${ $image.find('img').attr('src') }")`
				} );
				$image.find('img').css( {
					"visibility": "hidden"
				} );
			} );
		}

	};
	
	let onJuicerLoaded = () => {
		let _juicerFeedController = new JuicerFeedController();
		_juicerFeedController.run();
	};

	/**
	 * Not loaded into Dispatcher as Juicer calls this
	 * particlular function when it loads.
	 * @type {Function}
	 */
	window.onJuicerLoaded = onJuicerLoaded;

} )( jQuery, window, document );
