// Initialise Slick slider

( function( $, window, document, undefined ) {

	let slickDefault = {
				dots: false,
				arrows: false,
				infinite: true,
				adaptiveHeight: false,
				draggable: false
			},
			sliders = [
				{
					slider: $('.js-carousel'),
					args: {
					}
				},
				{
					slider: $('.js-carousel--autoplay'),
					args: {
						autoplay: true,
						autoplaySpeed: 4000,
						pauseOnHover: false,
						// This callback will be run to determine dynamic args
						_callback: function( $element ) {
							let dots = $element.get(0).hasAttribute('data-dots') ? $element.attr('data-dots') : false;
							let draggable = $element.get(0).hasAttribute('data-draggable') ? $element.attr('data-draggable') : false;

							if ( 'true' == dots || dots ) {
								dots = true;
							} else {
								dots = false;
							}

							if ( 'true' == draggable || draggable ) {
								draggable = true;
							} else {
								draggable = false;
							}
							
							return {
								dots,
								draggable
							};
						}
					}
				},
				{
					slider: $('.js-linked-carousel'),
					args: {
						autoplay: true,
						autoplaySpeed: 4000,
						pauseOnHover: false,
						// This callback will be run to determine dynamic args
						_callback: function( element ) {
							let linkedID = $(element).data('linkedTo');
							
							return {
								asNavFor: linkedID
							};
						}
					}
				}
			]; 

	$.each( sliders, (index, object) => {

		let $slider = $( object.slider ),
				args = $.extend( {}, slickDefault, object.args );

		$slider.each( (index, slider) => {

			let $_slider = $(slider);

			if ( undefined !== args._callback ) {
				args = $.extend( {}, args, args._callback( $_slider ) );
			}

			$_slider.slick( args );

		} );

	} );

	// Link up custom controls
	$('.js-carousel-control').on( 'click', (event) => {

		let $target = $(event.target).closest('.js-carousel-control'),
				$controls = $( $target.attr( 'aria-controls' ) ),
				action = $target.attr( 'data-action' );

		if ( $controls.length ) {

			$controls.slick( action );

			event.preventDefault();
			return false;

		}

	} );
	
} )( jQuery, window, document );
