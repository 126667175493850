// Filter items based on their selector

( function( $, window, document, undefined ) {
	
	let FilterController = class FilterController {

		constructor( $items ) {
			let _ = this;

			_.$items = $items;

			/**
			 * A callback function that can be set by a user
			 * to determine the element to be hidden/shown
			 * when filtering.
			 *
			 * Defaults to showing and hiding the 
			 * element that matched the filter selector. 
			 * 
			 * @param  {Number} index 			The index of the element.
			 * @param  {DOMElement} element The element that matched the filter selector.
			 * @return {DOMElement}         The element that should be shown/hidden, 
			 *                              e.g. element.parentNode
			 */
			_._itemMapCallback = ( index, element ) => ( element );
		}

		filter( selector ) {
			let _ = this,
					$items = _.$items.map( _._itemMapCallback ),
					$matches = _.$items.filter( selector ).map( _._itemMapCallback );

			$items.hide();
			$matches.show();

			return _;
		}

		setItemMapCallback( func ) {
			let _ = this;

			_._itemMapCallback = func;
		}

	};

	let $items = $('.js-filter-item'),
			$buttons = $('.js-filter-button'),
			buttonActiveClass = 'is-active js-filter-button--is-active',
			_filter = new FilterController( $items );

	// Set the filter to show and hide the parent element of the one matched
	_filter.setItemMapCallback( (index, element) => (element.parentNode) );

	$buttons.on( 'click', (event) => {
		let $this = $(event.target),
				selector = '*',
				isActive;

		if ( !$this.hasClass('.js-filter-button') ) {
			$this = $this.closest( '.js-filter-button' );
		}

		isActive  = $this.hasClass( buttonActiveClass );
		if ( !isActive ) {
			selector = $this.attr( 'data-filter' );
		}

		_filter.filter( selector );

		// Update active state
		$buttons.removeClass( buttonActiveClass );
		if ( !isActive && '*' !== selector ) {
			$this.addClass( buttonActiveClass );
		}

		event.preventDefault();
		return false;
	} );

} )( jQuery, window, document );
