// Listen for state changes on header

( function( $, window, document, undefined ) {
	
	let $header = $('.js-header'),
			headerHasChangedState = false,
			hiddenClass = 'is-hidden',
			transparentClass = 'is-transparent',
			minThreshold = 90,
			velocityController = new VelocityScrollController( $header, {
				minThreshold,
				onChangeState: function( direction, options ) {
					let $element = $(this);

					$element.toggleClass( hiddenClass, direction > 0 );

					$element.removeClass( 'header--frontpage' );
					headerHasChangedState = true;
				}
			} );

	// Change state on scroll down
	function changeHeaderColor() {
		if ( $header.hasClass( hiddenClass ) && $(window).scrollTop() <= minThreshold ) {
			$header.addClass( 'is-transparent--immediate' );
		} else {
			$header.removeClass( 'is-transparent--immediate' );
		}

		$header.toggleClass( transparentClass, $(window).scrollTop() <= minThreshold );
	}

	changeHeaderColor();
	$(window).on( 'scroll', changeHeaderColor );

} )( jQuery, window, document );
