// Init SVG maps

( function( $, window, document, undefined ) {
	
	let $tenantsMap = $('#tenants-map'),
			$lettingsMap = $('#lettings-map'),
			tenantsMapController,
			lettingsMapController;

	if ( $tenantsMap.length ) {
		tenantsMapController  = new SVGMapContoller( $tenantsMap );
	}

	if ( $lettingsMap.length ) {
		lettingsMapController  = new SVGMapContoller( $lettingsMap );
	}

} )( jQuery, window, document );
