// SVG Map Controller

( function( $, window, document, undefined ) {
	
	let SVGMapContoller = class SVGMapContoller {

		constructor( $element ) {
			let _ = this;

			_.$element = $element;

			_.$draggableContainer = _.$element.parent();
			_.$container = _.$draggableContainer.parent();
			// Only buildings with an id attribute will have an associated section
			_.$buildings = _.$element.find( '[id]' );

			_._$baseLayer = _.$element.find('#baselayer');

			// Since there could be IDs for lettings areas, filter 
			// to only IDs that have a corresponding info section
			_.$buildings = _.$buildings.filter( (index, building) => {
				let $tenant = _._getTenant( $(building).attr('id') );

				return !!$tenant;
			} );

			_.$zoomControls = $('.js-svgmap-zoom');

			_.hoverColor = '#f0668f';
			_.hoverStyle = {
				fill: _.hoverColor
			};
			_._$hoverTarget = null;

			_._infoWindowHiddenClass = 'is-hidden';
			_._infoWindowFlippedClass = 'svg-map__infowindow--left';

			// Initial scale as well as state variable
			_._scale = 0.9;
			_._minScale = 0.4;
			_._maxScale = 2;

			_._isBeingDragged = false;

			_._zoomIntervalID = null;
			_._zoomIntervalTimeout = 150;

			_._bindEvents();
		}

		_getTenant( id ) {
			let _ = this,
					$tenant = $( `[data-tenant="${id}"]` );

			if ( !$tenant.length ) {
				return false;
			}

			return $tenant;
		}

		_bindEvents() {
			let _ = this;

			_.$buildings.mouseenter( ( event ) => {
				if ( _._isBeingDragged ) {
					return;
				}
				
				let $target = $( event.target );
				
				// Close any previously open windows
				if ( null !== _._$hoverTarget && $target.attr('id') !== _._$hoverTarget.attr('id') ) {
					_.resetStyle( _._$hoverTarget );
					_.closeInfoWindow( _._$hoverTarget.attr('id') );
					_._$hoverTarget = null;
				}

				_._$hoverTarget = $target;
				_.setStyle( $target, _.hoverStyle );
				_.openInfoWindow( $target.attr('id'), $target.get(0).getBoundingClientRect() );
			} );

			// When hover returns to the base layer, close open windows
			_._$baseLayer.mouseenter( ( event ) => {
				if ( _._isBeingDragged || null === _._$hoverTarget ) {
					return;
				}
				
				_.resetStyle( _._$hoverTarget );
				_.closeInfoWindow( _._$hoverTarget.attr('id') );
				_._$hoverTarget = null;
			} );

			// On click, go through to the tenants single page
			_.$buildings.click( (event) => {
				let $target = $( event.target );

				_._goToLink( $target.attr('id') );
			} );

			// Make the map container draggable for navigation
			_.$draggableContainer.draggable( {
				scroll: false,
				start: () => {
					_._isBeingDragged = true;

					if ( null !== _._$hoverTarget ) {
						_.resetStyle( _._$hoverTarget );
						_.closeInfoWindow( _._$hoverTarget.attr('id') );
					}
				},
				stop: () => {
					_._isBeingDragged = false;

					if ( null !== _._$hoverTarget ) {
						_.setStyle( _._$hoverTarget, _.hoverStyle );
						_.openInfoWindow( _._$hoverTarget.attr('id'), _._$hoverTarget.get(0).getBoundingClientRect() );
					}
				},
			} );

			/**
			 * Link up zoom controls so that they keep zooming 
			 * if the button is held down.
			 * Starts a timer and then zooms in specific increments.
			 */
			_.$zoomControls.mousedown( (event) => {
				let $target = $( event.target ),
						amount;

				if ( $target.not( '.js-svgmap-zoom' ) ) {
					$target = $target.closest( '.js-svgmap-zoom' );
				}

				amount = $target.attr( 'data-scale' );

				// Run first call straight away
				_._incrementalZoom( amount );

				_._zoomIntervalID = setInterval( () => {
					_._incrementalZoom( amount );
				}, _._zoomIntervalTimeout );

				event.preventDefault();
				return false;
			} );

			/**
			 * Listen for mouseup events to cancel the timer.
			 * Listened for events across the document, to prevent
			 * the situation where somebody clicks on the button,
			 * moves the mouse off, and then releases the button 
			 * on a separate element.
			 */
			$(document).mouseup( (event) => {
				_._clearInterval();
			} );

			return _;
		}

		_incrementalZoom( amount ) {
			let _ = this;

			if ( amount && 0 !== amount ) {
				_.zoom( amount );
			}

			return _;
		}

		_clearInterval() {
			let _ = this;

			if ( null !== _._zoomIntervalID ) {
				clearInterval( _._zoomIntervalID );
			}

			return _;
		}

		setStyle( $element, styles ) {
			let _ = this,
					$hoverGroup = $( `#${ $element.attr('id') }-hover` );

			if ( $hoverGroup.length ) {
				$hoverGroup.css( 'opacity', 1 );
			} else {			
				$element.css( styles );
			}

			return _;
		}

		resetStyle( $element ) {
			let _ = this,
					$hoverGroup = $( `#${ $element.attr('id') }-hover` );

			if ( $hoverGroup.length ) {
				$hoverGroup.css( 'opacity', 0 );
			} else {			
				$element.attr( 'style', '' );
			}

			return _;
		}

		openInfoWindow( id, triggerRect ) {
			let _ = this,
					$target = _._getTenant( id ),
					shouldBePositionedLeft;

			if ( !$target ) {
				return _;
			}

			shouldBePositionedLeft = _._doRectsIntersect( triggerRect, _._getRect( $target ) );

			if ( shouldBePositionedLeft ) {
				$target.addClass( _._infoWindowFlippedClass );
			} else {
				$target.removeClass( _._infoWindowFlippedClass );
			}

			$target.removeClass( _._infoWindowHiddenClass );

			return _;
		}

		_goToLink( id ) {
			let _ = this,
					$target = _._getTenant( id );

			if ( !$target || !$target.data('permalink') ) {
				return _;
			}

			window.location.href = $target.data('permalink');

			return _;
		}

		_doRectsIntersect( rect1, rect2 ) {
			let _ = this;

			return (
				rect1.right >= rect2.left
			);
		}

		_getRect( $element ) {
			let _ = this,
					rect,
					isFlipped = $element.hasClass( _._infoWindowFlippedClass );

			$element.removeClass( _._infoWindowHiddenClass );
			$element.removeClass( _._infoWindowFlippedClass );
			rect = $element.get(0).getBoundingClientRect();
			$element.addClass( _._infoWindowHiddenClass );

			if ( isFlipped ) {
				$element.addClass( _._infoWindowFlippedClass );
			}

			return rect;
		}

		closeInfoWindow( id ) {
			let _ = this,
					$target = _._getTenant( id );

			if ( $target ) {
				$target.addClass( _._infoWindowHiddenClass );
			}

			return _;
		}

		zoom( amount ) {
			let _ = this,
					newScale,
					transform;

			newScale = _._scale + parseFloat( amount );

			// Constrain to min and max
			newScale = Math.min( newScale, _._maxScale );
			newScale = Math.max( newScale, _._minScale );

			_._scale = newScale;
			transform = _._getTransform();

			_.$element.css( { 
				transform: transform
			} );

			return _;
		}

		_getTransform() {
			let _ = this,
					scaleTransform = `scale( ${_._scale} )`;

			// Return another template literal to allow
			// compound transforms to be built in future
			return `${scaleTransform}`;
		}

	};


	// Expose API
	window.SVGMapContoller = SVGMapContoller;

} )( jQuery, window, document );
