// Stop body scroll when fixed element containers are open.
// Keeps track of how many levels are frozen and only restores scroll once all levels have been unfrozen.

( function( $, window, document, undefined ) {
	
	let levelsFrozen = 0,
			scrollTop = $(window).scrollTop();

	let ScrollController = {

		freeze: () => {
			levelsFrozen++;

			if ( 1 === levelsFrozen ) {
				scrollTop = $(window).scrollTop();

				$('body').css( {
					position: 'fixed',
					top: -(scrollTop  - parseInt( $('html').css('marginTop') ) ) + 'px',
					overflowY: 'scroll',
				} );
			}
		},

		restore: () => {
			levelsFrozen--;

			if ( 0 === levelsFrozen ) {
				$('body').css( {
					'position': '',
					'top': '',
					'overflow-y': '',
				} );
				$(window).scrollTop( scrollTop );
			}
		},

		isFrozen: () => levelsFrozen > 0,

		levelsFrozen: () => levelsFrozen

	};

	// Expose methods
	window.ScrollController = ScrollController;

} )( jQuery, window, document );
