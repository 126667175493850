// Init WOW.js

( function( $, window, document, undefined ) {
	
	var boxClass = 'js-wow',
			animateClass = 'is-animated',
			wow = new WOW( {
				boxClass: 'js-wow',
				animateClass: 'is-animated'
			} ),
			$wowElements = $( '.' + boxClass );

	// Show immediately for any elements already scrolled past
	$(document).on( boxClass + '-initialised', function() {

		$wowElements.each( function( index, node ) {
			var $this = $(node);

			if ( $(window).scrollTop() > 0 && hasBeenScrolledPast( $this ) ) {
				wow.show( node );
			}
		} );

	} );

	wow.init();

} )( jQuery, window, document );
