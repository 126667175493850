// Scroll to anchor links with specific speed
( function( $, window, document, undefined ) {

	/**
	 * Constructor
	 * @param {String} hash An element ID selector.
	 */
	var Hash = function( hash ) {
		var _ = this;

		_.hash = hash;
	};

	/**
	 * Check if the hash exists within the DOM.
	 * Returns false if the hash is only a #.
	 * @return {Boolean}
	 */
	Hash.prototype.exists = function() {
		var _ = this,
				$test = $( _.hash );

		if ( '#' === _.hash ) {
			return 0;
		}

		return $test.length;
	};

	/**
	 * Get a jQuery element representation for the hash.
	 * @return {jQuery}
	 */
	Hash.prototype.getjQueryElement = function() {
		var _ = this,
				$element = $( _.hash );

		return $element;
	};
	
	/**
	 * Constructor
	 * @param {Object} options A set of options to setup the class.
	 *                         See defaults object below for an 
	 *                         explanation of each option.
	 * @param {Number} speed Optional, The speed of the scroll in ms. 
	 * 											 Defaults to 250ms.
	 */
	var ScrollTo = function( options ) {
		var _ = this;

		_._defaults = {
			/**
			 * The speed of the scroll in ms. 
			 * @type {Number}
			 */
			speed: 250,
			/**
			 * The amount, in pixels, to offset the final scroll position by.
			 * Use a negative number to finish scrolling 
			 * at a point above the element or position passed.
			 * Defaults to 30px above the element or position
			 * @type {Number}
			 */
			offset: -30
		};

		_.options = $.extend( {}, _._defaults, options );
	};

	/**
	 * Scroll to a specific element on the page.
	 * @param  {jQuery} $element The element to scroll to.
	 * @return {self}
	 */
	ScrollTo.prototype.element = function( $element ) {
		var _ = this,
				scrollTop = $element.offset().top;

		_.position( scrollTop + _.options.offset );
		
		return _;
	};

	/**
	 * Scroll to a specific point on the page.
	 * @param  {Number} y  The y coordinate to scroll to.
	 * @return self
	 */
	ScrollTo.prototype.position = function( y ) {
		var _ = this;

		$('html, body').animate({
      scrollTop: y
    }, _.options.speed);

    return _;
	};

	var _scrollTo = new ScrollTo();

	// Bind events
	$( 'a[href^="#"]' ).on( 'click', function( event ) {

		var $this = $(this),
				_hash = this.hash,
				$hash = new Hash( _hash );

		if ( $hash.exists() ) {
			_scrollTo.element( $hash.getjQueryElement() );
		}

		// Don't prevent default so that URL is updated

	} );

} )( jQuery, window, document );
