// Init Odometer.js

( function( $, window, document, undefined ) {
	
	let $odometers = $('.odometer'),
			odometers = [],
			GLOBAL_DELAY = 500,
			keywords = {
				currentYear: new Date().getFullYear()
			};

	$odometers.each( (index, odometer) => {

		let $this = $(odometer),
				odometerFrom = '_' === $this.attr( 'data-odometer-from' ).substr( 0, 1 ) ? keywords[ $this.attr( 'data-odometer-from' ).substr( 1 ) ] : $this.attr( 'data-odometer-from' ),
				odometerTo = '_' === $this.attr( 'data-odometer-to' ).substr( 0, 1 ) ? keywords[ $this.attr( 'data-odometer-to' ).substr( 1 ) ] : $this.attr( 'data-odometer-to' ),
				_odometer = new Odometer({
				  el: odometer,
				  value: odometerFrom,

				  // Any option (other than auto and selector) can be passed in here
				  format: '(,ddd)',
				  theme: 'default',
				});

		odometers.push( _odometer );

	} );

	let updateOdometers = ( delay ) => {
		$odometers.each( (index, odometer) => {

			let $this = $(odometer),
					odometerTo = '_' === $this.attr( 'data-odometer-to' ).substr( 0, 1 ) ? keywords[ $this.attr( 'data-odometer-to' ).substr( 1 ) ] : $this.attr( 'data-odometer-to' ),
					callback = () => {
						$this.html( odometerTo );
					};

			if ( hasBeenScrolledPast( $this ) ) {

				if ( delay ) {
					setTimeout( callback, delay );
				} else {
					callback();
				}

			}

		} );
	};

	setTimeout( updateOdometers, GLOBAL_DELAY * 2 );

	$(window).on( 'scroll', throttle( () => {
		updateOdometers( 0 );
	}, 150 ) );

} )( jQuery, window, document );
