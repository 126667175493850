// Utility JavaScript functions
// Comment-out switches are wrapped around each block
// Use them to turn on/off specific utilities

// Polyfill
/*
if (!Array.isArray) {
  Array.isArray = function(arg) {
    return Object.prototype.toString.call(arg) === '[object Array]';
  };
}
//*/

/**
 * Check if an element has been scrolled past, 
 * i.e. it is on screen or above the viewport.
 * @param  {jQuery}  $element The element to check.
 * @return {Boolean}          
 */
function hasBeenScrolledPast( $element ) {
  var elementTop = $element.offset().top,
      screenTop = jQuery(window).scrollTop(),
      screenBottom = screenTop + jQuery(window).outerHeight();

  return screenBottom >= elementTop;
}

/**
 * Get the true window width, without scrollbars.
 * @return integer The window width in px.
 */
//*
function windowWidth() {
  // Temporarily disable scrollbar
  jQuery('body').css( {
    overflow: 'hidden',
  } );

  // Get window width
  var w = jQuery(window).width();

  // Remove styles
  jQuery('body').css( {
    overflow: '',
  } );

  return w;
}
//*/

/**
 * Get the window height.
 * @return integer The window height in px.
 */
//*
function windowHeight() {
  return jQuery(window).height();
}
//*/

//*
function throttle(fn, threshold, scope) {
  threshold = threshold || 250;
  var last,
      deferTimer;
  return () => {
    var context = scope || this;

    var now = +new Date(),
        args = arguments;
    if (last && now < last + threshold) {
      // hold on to it
      clearTimeout(deferTimer);
      deferTimer = setTimeout( () => {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}
//*/

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
//*
function debounce(func, wait, immediate) {
	var timeout;
	return () => {
		var context = this, args = arguments;
		var later = () => {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}
//*/
