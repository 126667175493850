// Detect scroll position and velocity and take some action

( function( $, window, document, undefined ) {
	
	let VelocityScrollController = class VelocityScrollController {

		constructor( $element, options ) {
			let _ = this;

			_.$element = $element;

			_._defaults = {
				/**
				 * The velocity, in pixels per second, to scroll down 
				 * before triggering a state change.
				 * Defaults to 0, triggering straight away.
				 * @type {Number}
				 */
				downThreshold: 0,

				/**
				 * The velocity, in pixels per second, to scroll up 
				 * before triggering a state change.
				 * Defaults to 10.
				 * @type {Number}
				 */
				upThreshold: 10,

				/**
				 * The amount of pixels from the top of the page,
				 * that must be scrolled before a change is triggered.
				 * 
				 * When scrolling up a state change will be 
				 * triggered once this minimum point is hit.
				 * @type {Number}
				 */
				minThreshold: 25,

				/**
				 * Callback when the state of the element should change.
				 * Allows user defined behaviour per element.
				 *
				 * @param {Function} A function with these parameters:
				 *
				 * @param {Element} this  	  The DOM Element that changed state.
				 *                            Not a jQuery element.
				 * @param {Number}  direction The direction of the scroll.
				 *                            > 0 for scrolling down.
				 *                            < 0 for scrolling up.
				 * @param {Object} options 		The options passed to this class.
				 */
				onChangeState: function( direction, options ) {
					let $element = $(this);

					if ( direction > 0 ) {
						$element.hide();
					} else if ( direction < 0 ) {
						$element.show();
					}
				}
			};

			_.options = $.extend( {}, _._defaults, options );

			// Class private properties
			_._scrollDelta = 0;
			_._scrollTop = 0;
			_._aboveMinThreshold = false;

			// Run an initial on scroll check
			if ( $(window).scrollTop() > 0 ) {
				_._onScroll();
			}

			_._bindEvents();
		}

		_bindEvents() {
			let _ = this;

			$(window).on( 'scroll', (event) => {
				// Need access to class as this variable
				// so using anon function to call
				_._onScroll( event );
			} );

			return _;
		}

		_onScroll( event ) {
			let _ = this;

			if ( $(window).scrollTop() < _.options.minThreshold && _._aboveMinThreshold ) {
				
				// If below the min threshold only trigger an upwards state change
				// Checks for the presence of a flag that the scroll was previously above the threshold
				_.options.onChangeState.call( _.$element.get(0), -1, _.options );

				_._aboveMinThreshold = false;

			} else if ( $(window).scrollTop() >= _.options.minThreshold ) {

				// Determine scroll delta
				// > 0 for scrolling down, < 0 for scrolling up
				_._scrollDelta = $(window).scrollTop() - _._scrollTop;

				let absScrollDelta = Math.abs( _._scrollDelta );

				if ( 
					( _._scrollDelta > 0 && absScrollDelta > _.options.downThreshold ) || 
					( _._scrollDelta < 0 && absScrollDelta > _.options.upThreshold )
				) {
					_.options.onChangeState.call( _.$element.get(0), _._scrollDelta, _.options );
				}

				_._aboveMinThreshold = true;
				
			}

			// Reset state variables for next run
			_._scrollTop = $(window).scrollTop();
			_._scrollDelta = 0;
		}

	};


	// Expose API
	window.VelocityScrollController = VelocityScrollController;

} )( jQuery, window, document );
