// Initialise Train animation on scroll

( function( $, window, document, undefined ) {

	let trainSelector = '#train',
			$train = $(trainSelector);

	if ( !$train.length ) {
		return;
	}

	let controller = new ScrollMagic.Controller(),
			scene = new ScrollMagic.Scene( {
				triggerElement: trainSelector, 
				duration: 500,
				triggerHook: 1,
				reverse: false
			} ),
			easeOutBack = (t) => ( t*((t=t/1-1)*t*((2.70158)*t+1.70158)+1)+0 );

	scene.addTo( controller );

	scene.on( "progress", (event) => {

		let progress = event.progress.toFixed(3),
				percentage = ( 1 - easeOutBack( progress ) ) * -1;

		$train.css( {
			transform: `translateX( ${percentage * 100 - 10}% )`
		} );

	} );

	scene.on( "end", (event) => {

		$train.animate( {'margin-left': 30}, 600 );

		setTimeout( () => {
			$train.animate( {'margin-left': -5}, 125 );
		}, 600 );

	} );
	
} )( jQuery, window, document );
